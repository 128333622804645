import { WhoAmIResponse, EmbeddedUser } from "~~/types"
const runtimeConfig = useRuntimeConfig()

const userData: Ref<WhoAmIResponse | null> = useState("userData")

const getWhoAmI = async () => {
  const { data: user, error } = await useFetch<WhoAmIResponse>("/whoami/", {
    baseURL: runtimeConfig.public.baseURL,
    headers: {
      Authorization: `Token ${localStorage.getItem("token")}`,
    },
  })
  if (error && error.value) navigateTo("/login/")
  userData.value = user.value as WhoAmIResponse
  return userData.value
}

const getUsers = async () => {
  const { data, error } = await useFetch<EmbeddedUser[]>("/users/", {
    baseURL: runtimeConfig.public.baseURL,
    headers: {
      Authorization: `Token ${localStorage.getItem("token")}`,
    },
  })

  return { data, error }
}

export default () => {
  return { getWhoAmI, getUsers }
}
